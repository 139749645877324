<template>  
    <mbs-page-spinner v-if="false" />  
    <v-card outlined id="print_template" v-else>
        <v-card-text class="mb-9 px-6 py-10">
            <v-layout justify-center align-center class="mt-9 mb-9">
                <div class="text-center py-5">
                    <div>{{selectedCompany?selectedCompany.company_name:'...'}}</div>
                    <div class="font-weight-bold" v-if="stock_order">
                        <span v-if="stock_order.order_type == DATA.ITEMS.STOCK_RECEIVES.value">Received Stock</span>
                        <span v-else>**** ({{stock_order.order_type}}) ****</span>
                    </div>
                    <div>{{MBS.date.moment().format('dddd, Do, MMMM YYYY')}}</div> 
                </div>
            </v-layout>
            <hr> 
            <v-list>
                <v-list-item>
                    <v-list-item-content>Number</v-list-item-content>
                    <v-list-item-action>{{stock_order?stock_order.code:'---'}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>Delivery Date</v-list-item-content>
                    <v-list-item-action>{{stock_order?stock_order.delivery_date:"---"}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>Supplier Name</v-list-item-content>
                    <v-list-item-action>{{stock_order?stock_order.supplier_name:"---"}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>Payment Method</v-list-item-content>
                    <v-list-item-action>
                        <v-card min-width="80" tile flat class="text-right">
                            {{stock_order?stock_order.payment_method_name:"---"}}
                        </v-card>
                    </v-list-item-action>
                </v-list-item> 
            </v-list>
            <br> 
            <v-list-item>
                <v-list-item-content>Revenue</v-list-item-content>
                <v-list-item-action>TOTAL</v-list-item-action>
            </v-list-item>
            <hr> 
            
            <v-divider></v-divider>
            <!-- items -->
            <v-list-group
                v-model="expend_expenses"
                :no-action ="true" 
                :sub-group ="false" 
                append-icon="" 
                >
                <template v-slot:activator class=" d ma-0 pa-0 "> 
                    <v-list-item-action class="ma-0 pa-0">
                        <v-icon class="pr-2" v-if="expend_expenses">expand_more</v-icon>
                        <v-icon class="pr-2" v-else>chevron_right</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">Items Name</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="expend_expenses">
                        <v-card class="font-weight-bold"  width="70" flat>
                            QTY
                        </v-card>
                    </v-list-item-action>
                    <v-list-item-action v-if="expend_expenses">
                        <v-card class="font-weight-bold" width="95" flat>
                            Price
                        </v-card>
                    </v-list-item-action>
                    <v-list-item-action v-if="expend_expenses">
                        <v-card class="font-weight-bold" width="80" flat>
                            Discount
                        </v-card> 
                    </v-list-item-action>
                    
                    <v-list-item-action v-if="expend_expenses">
                        <v-card class="font-weight-bold" width="95" flat>
                           Total Price
                        </v-card>
                    </v-list-item-action>
                    <v-list-item-action v-if="!expend_expenses">
                        {{stock_order?stock_order.total_quantity:'..'}}
                    </v-list-item-action><v-list-item-action v-if="!expend_expenses">
                        {{stock_order?stock_order.total_quantity:'..'}}
                    </v-list-item-action>
                    <v-list-item-action v-if="!expend_expenses">
                        {{stock_order?stock_order.total_price_:'..'}}
                    </v-list-item-action>
                </template>

                <v-list-item class="pl-13"
                    v-for="(item, i) in StockOrderItems"
                    :key="i"
                    link >
                    <v-list-item-title >{{item.name}}</v-list-item-title> 
                    <v-list-item-action > 
                        <v-card width="90" flat>
                            {{item.quantity}}
                        </v-card>
                    </v-list-item-action>
                    
                     <v-list-item-action>
                        <v-card width="130" flat>
                            {{MBS.actions.money(item.price)}}
                        </v-card> 
                    </v-list-item-action>
                    <v-list-item-action> 
                        <v-card width="130" flat>
                            {{MBS.actions.money(item.discount)}}
                        </v-card>
                    </v-list-item-action>
                    <v-list-item-action>
                        <v-card width="130" flat>
                            {{MBS.actions.money(CALCULATE_PRICE(item))}}
                        </v-card> 
                    </v-list-item-action>
                </v-list-item>
                <v-divider class="ml-13"></v-divider>
                <v-list-item class="font-weight-bold pl-13">
                    <v-list-item-content>Total</v-list-item-content>
                    <!-- <v-list-item-action>{{stock_order?stock_order.total_quantity:'..'}}</v-list-item-action> -->
                    <v-list-item-action>{{stock_order?stock_order.total_price_:'..'}}</v-list-item-action>
                </v-list-item>
            </v-list-group>
            <br>
            <v-list>
                <v-list-item>
                    <v-list-item-content>Tax</v-list-item-content>
                    <v-list-item-action>{{MBS.actions.money(0)}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>Extra Cost</v-list-item-content>
                    <v-list-item-action>
                        <v-card min-width="80" tile flat class="text-right">
                            {{MBS.actions.money(0)}} 
                        </v-card>
                    </v-list-item-action>
                </v-list-item> 
            </v-list>
            <hr>
            <v-list-item class="font-weight-bold">
                <v-list-item-content>TOTAL AMOUNT</v-list-item-content>
                <v-list-item-action>{{stock_order?stock_order.total_price_:'..'}}</v-list-item-action>
            </v-list-item> 
            <br> 
        </v-card-text> 
    </v-card>
</template>

<script>
    import DATA from "../../../plugins/DATA"
    let NAME = 'STOCK-ORDER-TEMP'
    import {mapState} from "vuex"
    export default {
        props:['stock_order','sale_orders','expenses'], 
        name:NAME,
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                expend_expenses:true,
            } 
        }, 
        mounted(){ 
        },
        computed:{  
            StockOrderItems(){ 
                let stock_order = this.stock_order  
                if(!stock_order){return null} 
                return stock_order.joined_items?stock_order.joined_items:stock_order.items
            },  


            
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },   
            Joined_SaleOrders(){
                let sales = this.sale_orders
                if(!sales){return null}
                let join = this.MBS.actions.JOIN({
                    items:sales,
                    name:DATA.ITEMS.SALE_ORDERS.values
                })
                return join
            } ,
            CalculatedExpensesAccounts(){ 
                let expenses = this.expenses
                if(!expenses){return null}
                let accounts = []
                for (const expense of expenses) {
                    let amount = this.toNumber(expense.amount)
                    let expense_account = expense.expense_account
                    if (expense_account) {
                        let index =  accounts.findIndex(account=>{
                            return account.code == expense_account.code
                        })
                        if (index==-1) {
                            accounts.push({
                                ...expense_account,
                                total_amount:amount,
                                total_amount_:this.MBS.actions.money(amount),
                            })
                        } else {
                            accounts[index].total_amount = accounts[index].total_amount+amount
                            accounts[index].total_amount_ = this.MBS.actions.money(accounts[index].total_amount)
                        }
                    } 
                }
                return accounts
            },  
            TotalSalesOrderItems(){ 
                // let sales = this.Joined_SaleOrders 
                let sales = this.sale_orders
                let total = 0
                if(!sales){return total}
                sales.forEach(element => {
                    total = total+this.toNumber(element.total_sold_price) 
                });
                return total
            }, 
            TotalCostSalesOrderItems(){ 
                // let sales = this.Joined_SaleOrders 
                let sales = this.sale_orders 
                let total = 0
                if(!sales){return total}
                sales.forEach(element => {   
                    total = total+this.toNumber(element.total_cost_price) 
                });
                return total
            }, 
            TotalExpenses(){ 
                let expenses = this.CalculatedExpensesAccounts
                let total = 0
                if(!expenses){return total}
                expenses.forEach(element => {
                    total = total+this.toNumber(element.total_amount)
                });
                return total
            },  
            GrossProfit(){ 
                let totalSales = this.TotalSalesOrderItems
                let totalCost = this.TotalCostSalesOrderItems
                return totalSales - totalCost
            },  
             
        },
        methods:{
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
